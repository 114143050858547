import React, { useMemo, useCallback, useState } from 'react';
import {
    Autocomplete,
    TextField,
    Button,
    ListSubheader,
    Box,
    Paper,
    MenuItem,
    type AutocompleteRenderGroupParams,
    Typography,
} from '@mui/material';
import { styled, type SimplePaletteColorOptions } from '@mui/material/styles';
import { ArrowRightRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { theme } from 'FrontRoyalMaterialUiForm';
import { type ProgramTypeConfig, ProgramTypeConfigs } from 'Program';
import { type ProgramTypeGroup } from 'Admin/Admin.types';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import useProgramTypeGroups from 'Admin/hooks/useProgramTypeGroups';

const NEUTRAL_PALETTE = theme.palette.neutral as SimplePaletteColorOptions;
const LIST_SUBHEADER_HEIGHT = 24;

const ProgramTypeGroupSubheader = styled(ListSubheader)(() => ({
    display: 'flex',
    alignItems: 'center',
    height: LIST_SUBHEADER_HEIGHT,
    lineHeight: `${LIST_SUBHEADER_HEIGHT}px`,
    backgroundColor: NEUTRAL_PALETTE.light,
}));

const ProgramTypeSubheader = ProgramTypeGroupSubheader;

const SubheaderText = styled(Typography)(() => ({
    lineHeight: `${LIST_SUBHEADER_HEIGHT}px`,
    textTransform: 'uppercase',
}));

interface HierarchicalCohortAutocompleteProps {
    cohorts: CohortForAdminListsAttrs[];
    onChange: (cohort: CohortForAdminListsAttrs | CohortForAdminListsAttrs[] | [] | null) => void;
    value?: CohortForAdminListsAttrs[] | CohortForAdminListsAttrs | [] | null;
    disabled?: boolean;
    multiple?: boolean;
    size?: 'small' | 'medium';
    sx?: Record<string, unknown>;
}

type CustomListboxComponentProps = React.HTMLAttributes<HTMLElement> & {
    excludeOldCohorts: boolean;
    onToggleHideOldCohorts: () => void;
};

const ListboxComponent = React.forwardRef<HTMLElement, CustomListboxComponentProps>(
    ({ children, excludeOldCohorts, onToggleHideOldCohorts, ...other }, ref) => (
        <Paper
            component="div"
            ref={ref as React.Ref<HTMLDivElement>}
            {...other}
            sx={{ width: '100%', maxHeight: 300, marginTop: '-1px', padding: '0 !important', overflow: 'auto' }}
        >
            <Box sx={{ p: 1 }}>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onToggleHideOldCohorts();
                    }}
                    startIcon={excludeOldCohorts ? <Visibility /> : <VisibilityOff />}
                    fullWidth
                    variant="outlined"
                    data-visible={!excludeOldCohorts}
                    color={excludeOldCohorts ? 'primary' : 'warning'}
                    size="small"
                >
                    {excludeOldCohorts ? 'Show' : 'Hide'} old cohorts
                </Button>
            </Box>
            <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>{children}</ul>
        </Paper>
    ),
);

// eslint-disable-next-line max-lines-per-function
const HierarchicalCohortAutocomplete: React.FC<HierarchicalCohortAutocompleteProps> = ({
    cohorts,
    onChange,
    value,
    ...other
}) => {
    const { disabled, multiple, size } = other;
    const [isOpen, setIsOpen] = useState(false);
    const { programTypeGroups, excludeOldCohorts, setExcludeOldCohorts } = useProgramTypeGroups(cohorts) as {
        programTypeGroups: ProgramTypeGroup[];
        excludeOldCohorts: boolean;
        setExcludeOldCohorts: (excludeOldCohorts: boolean) => void;
    };

    const sortedCohorts = useMemo(
        () => programTypeGroups.flatMap(group => group.programTypes.flatMap(pt => pt.cohorts)),
        [programTypeGroups],
    );

    const getOptionGroup = useCallback(
        (option: CohortForAdminListsAttrs) => {
            const group = programTypeGroups.find(g => g.programTypes.some(pt => pt.cohorts.includes(option)));
            return group ? group.label : '';
        },
        [programTypeGroups],
    );

    const renderGroup = useCallback(
        (params: AutocompleteRenderGroupParams) => {
            const { key, group: groupLabel, children } = params;
            const optionGroup = programTypeGroups.find(g => g.label === groupLabel);
            const cohortsForProgramType = (programType: string) =>
                optionGroup?.programTypes.find(pt => pt.programType === programType)?.cohorts;
            return (
                <React.Fragment key={key}>
                    <ProgramTypeGroupSubheader title={groupLabel} sx={{ bt: `1px solid ${NEUTRAL_PALETTE.main}` }}>
                        <SubheaderText variant="caption" noWrap>
                            {groupLabel}
                        </SubheaderText>
                    </ProgramTypeGroupSubheader>
                    {optionGroup?.programTypes.map(({ programType }) => (
                        <React.Fragment key={`${key}-${programType}`}>
                            <ProgramTypeSubheader
                                title={ProgramTypeConfigs[programType as keyof typeof ProgramTypeConfigs].label}
                                sx={{
                                    top: LIST_SUBHEADER_HEIGHT,
                                    bb: `1px solid ${NEUTRAL_PALETTE.main}`,
                                }}
                            >
                                <ArrowRightRounded fontSize="inherit" />
                                <SubheaderText variant="caption" noWrap>
                                    {
                                        (
                                            ProgramTypeConfigs[
                                                programType as keyof typeof ProgramTypeConfigs
                                            ] as ProgramTypeConfig
                                        ).label
                                    }
                                </SubheaderText>
                            </ProgramTypeSubheader>
                            {React.Children.toArray(children).filter(
                                opt =>
                                    React.isValidElement(opt) &&
                                    cohortsForProgramType(programType)?.some(c => c.id === opt.props.value),
                            )}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            );
        },
        [programTypeGroups],
    );

    return (
        <Autocomplete
            disabled={disabled}
            multiple={multiple || false}
            value={value}
            onChange={(_, newValue) => {
                onChange(newValue as CohortForAdminListsAttrs[]);
                setIsOpen(false);
            }}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={(_, reason) => {
                if (reason !== 'toggleInput') {
                    setIsOpen(false);
                }
            }}
            options={sortedCohorts}
            getOptionLabel={(option: CohortForAdminListsAttrs) => option?.name || ''}
            renderInput={params => <TextField {...params} label="Select Cohort(s)" />}
            renderOption={(props, option) => (
                <MenuItem value={option.id} {...props}>
                    {option.name}
                </MenuItem>
            )}
            groupBy={getOptionGroup}
            renderGroup={renderGroup}
            ListboxComponent={ListboxComponent as unknown as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            ListboxProps={
                {
                    excludeOldCohorts,
                    onToggleHideOldCohorts: () => setExcludeOldCohorts(!excludeOldCohorts),
                } as CustomListboxComponentProps
            }
            size={size || 'medium'}
            fullWidth
            disableCloseOnSelect
            clearOnBlur
            {...other}
        />
    );
};

export default HierarchicalCohortAutocomplete;
